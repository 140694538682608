export enum Cookies {
  ESSENTIAL = 'essential',
  FUNCTIONALITY = 'functionality',
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
  SOCIAL_MEDIA = 'socialMedia',
  AUTH_TOKEN = 'AUTH-TOKEN',
}

export enum Keys {
  TOKEN_STORAGE_KEY = 'VFQLMS.token',
  VIDEO_PREFERENCES_RATE = 'VFQLMS.videoPreferences-playbackRate',
  VIDEO_PREFERENCES_VOLUME = 'VFQLMS.videoPreferences-volume',
  VIDEO_PREFERENCES_CAPTIONS = 'VFQLMS.videoPreferences-captionsOn',
  PINNED_COURSES = 'VFQLMS.pinnedCourses',
  PINNED_PATHWAYS = 'VFQLMS.pinnedPathways',
  IDS_THEME = 'ids_theme_name',
  IDS_CUSTOM_PATH = 'ids_custom_path',
  COURSES_SELECTED_STATUS = 'courses-selected-status',
  PATHWAYS_SELECTED_STATUS = 'pathways-selected-status',
  LOGIN_ATTEMPTS_KEY = 'login-attempts',
  USER_CURRENCY = 'user-currency',
  USER_COUNTRY = 'user-country',
  STAY_SIGN_IN = 'stay_sign_in',
  CURRENT_CHAT_ID = 'currentChatId',
}
