import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { setLeaveContentModal } from '@/redux/app-slice';

import { useAppSelector, useAppDispatch } from './redux-hook';

export const useLinkInterceptor = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const {
    activeContent: { isActive },
  } = useAppSelector((state) => state.appState);

  useEffect(() => {
    const abortController = new AbortController();

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a');

      if (anchor?.href && anchor.origin === window.location.origin && !anchor.download) {
        e.preventDefault();

        if (isActive) {
          dispatch(setLeaveContentModal({ isOpen: true, leaveTo: anchor.href }));
          return;
        }

        router.push(anchor.href);
      }
    };

    document.addEventListener('click', handleClick, { signal: abortController.signal });

    return () => {
      abortController.abort();
    };
  }, [router, isActive, dispatch]);

  return null;
};
